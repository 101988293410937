import React, { useState, useRef, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PriceRange from '../PriceRange/PriceRange';
import useGetUrlParams from 'utils/useGetUrlParams';
import './Filters.css';

let arr = [];
let open = false;

const Filters = ({
  changeNightRange,
  changeWeekRange,
  changeAttrsOnValueChange,
  changeAttrsOnLoad,
  startSearchFromModal,
  enablePriceRangeSlider,
  enabledFilters,
  attributesList,
  priceRangeSliderSettings,
  changePriceMode,
  clearField,
  propertyAttributesIds,
  settings,
  dictionaries
}) => {
  const refsList = useRef([]);
  const [show, setShow] = useState(false);
  const [isCleanedPrice, setIsCleanedPrice] = useState(false);
  const [checkedAttrs, setCheckedAttrs] = useState([]);
  const [priceRangeState, setPriceRangeState] = useState(false);
  const [startedSearch, setStartedSearch] = useState(false);

  const [nightlyParamsRange] = useGetUrlParams('NightlyPriceRange');
  const [weeklyParamsRange] = useGetUrlParams('WeeklyPriceRange');
  const [priceFilterType] = useGetUrlParams('PriceFilterType');

  let [attributes] = useGetUrlParams('attrs');
  let [attrs] = useGetUrlParams('Attributes');

  const [paramsAttributesOpenModal, setParamsAttributesOpenModal] = useState([]);

  const handleClose = () => {
    setShow(false);
  };

  const handleClocedModal = () => {
    open = false;
  };

  const handleShow = (e) => {
    setShow(true);
    setStartedSearch(true);
  };

  const handleOpenModal = (e) => {
    open = true;
    setStartedSearch(true);
  };

  useEffect(() => {
    if (clearField > 0) {
      handleReset();
    }
  }, [clearField]);

  useEffect(() => {
    if (nightlyParamsRange !== null) {
      if (nightlyParamsRange !== '') {
        changeNightRange(nightlyParamsRange);
      }
      if (nightlyParamsRange === '') {
        changeNightRange('');
      }
    }

    if (weeklyParamsRange !== null) {
      if (weeklyParamsRange !== '') {
        changeWeekRange(weeklyParamsRange);
      }
      if (weeklyParamsRange === '') {
        changeWeekRange('');
      }
    }
  }, [priceRangeState]);

  useEffect(() => {
    if (priceFilterType !== null) {
      if (priceFilterType === 'Weekly') {
        changePriceMode("Weekly");
      }
      if (priceFilterType === 'Nightly') {
        changePriceMode("Nightly");
      }
      if (priceFilterType === '') {
        changePriceMode("");
      }
    }
  }, [priceFilterType]);

  useEffect(() => {
    // On open modal, get URL "Attributes" params if exist and make them checked
    let allAttributes = attrs || attributes || propertyAttributesIds;
 

    if (allAttributes && paramsAttributesOpenModal.length === 0 && allAttributes !== null) {
      setParamsAttributesOpenModal(allAttributes.toString());
    }

    if (!paramsAttributesOpenModal && allAttributes !== null) {
      setParamsAttributesOpenModal(allAttributes);
    }

    if (!open) {
      if (paramsAttributesOpenModal) {
        let attrArr;
        if (Array.isArray(paramsAttributesOpenModal)) {
          attrArr = paramsAttributesOpenModal;
        }
        if (typeof paramsAttributesOpenModal === 'string') {
          attrArr = paramsAttributesOpenModal.split(',');
        }

        for (let i = 0; i < refsList.current.length; i++) {
          if (typeof refsList.current[i] !== 'undefined' && refsList.current[i] !== null) {
            if (refsList.current[i].tagName === 'INPUT') {
              if (attrArr.includes(refsList.current[i].id)) {
                refsList.current[i].checked = true;
              }
            }
            if (refsList.current[i].tagName === 'SELECT') {
              for (let a = 0; a < refsList.current[i].length; a++) {
                const element = refsList.current[i][a];
                if (attrArr.includes(element.value)) {
                  refsList.current[i].selectedIndex = a;
                }
              }
            }
          }
        }
      }
    }

    setPriceRangeState(true); // init PriceRange Slider

  }, [handleShow]);

  useEffect(() => {
    // Showing the number of checked filters on the label
    let allAttributes = attributes || attrs || propertyAttributesIds;

    if (allAttributes) {
      let quantity;

      if (Array.isArray(allAttributes)) {
        quantity = allAttributes.filter(number => number !== "All");
      }
      if (typeof allAttributes === 'string') {
        quantity = allAttributes.split(',');
      }
      setCheckedAttrs(quantity.length);
      changeAttrsOnLoad(allAttributes);
    }

    if (attributes === '' && attrs === '' && propertyAttributesIds === '') {
      changeAttrsOnLoad('');
    }
  }, [attributes, attrs, propertyAttributesIds]);

  if (!enablePriceRangeSlider && !enabledFilters) {
    return false;
  }

  let attrsList = [];

  const handleChange = (e) => {
    // Getting all checked options on click
    for (let i = 0; i < refsList.current.length; i++) {
      if (refsList.current[i] != null && typeof refsList.current[i] !== "undefined") {
        if (refsList.current[i].tagName === 'INPUT') {
          if (refsList.current[i].checked) {
            arr.push(refsList.current[i].id);
          }
        }
      }
    }
    for (let i = 0; i < refsList.current.length; i++) {
      if (refsList.current[i] != null && typeof refsList.current[i] !== "undefined") {
        if (refsList.current[i].tagName === 'SELECT') {
          if (refsList.current[i].value !== "" && refsList.current[i].selectedIndex !== 0) {
            arr.push(refsList.current[i].value);
          }
        }
      }
    }
    if (arr.length > 0) {
      changeAttrsOnValueChange(arr.toString());
      setParamsAttributesOpenModal(arr);
    }
    setCheckedAttrs(arr.length);
    arr = [];
  }

  const startSearch = () => {
    startSearchFromModal();
    handleClose();
  }

  const handleReset = () => {
    setIsCleanedPrice(!isCleanedPrice); // Sending signal to Price Range to reset
    for (let i = 0; i < refsList.current.length; i++) {
      if (typeof refsList.current[i] !== 'undefined') {
        if (refsList.current[i]?.hasOwnProperty('checked')) {
          if (refsList.current[i].checked) {
            refsList.current[i].checked = false;
          }
        }
        if (refsList.current[i]?.tagName === 'SELECT') {
          refsList.current[i].selectedIndex = 0;
        }
      }
    }

    changeAttrsOnValueChange('');
    setParamsAttributesOpenModal([]);
    setCheckedAttrs([]);
    changeNightRange('');
    changeWeekRange('');
  }

  // Building template
  if (enabledFilters) {
    attrsList = attributesList.map(function (element, index) {
      let template = '';
      if (element.IsCheckBox === false) {
        let optionList = element.Options.map(function (option, i) {
          return <option value={option.Id} key={i}>{option.Name}</option>
        });
        let anyOption = '';
        if (!element.HideOptionAny) {
          anyOption = <option>Any</option>
        }

        template = (
          <Form.Select className="tw-max-w-[300px]" ref={element => (refsList.current[index] = element)} name={element.Alias} onChange={handleChange}  >
            {(anyOption !== '') ? anyOption : null}
            {optionList}
          </Form.Select>
        );
      } else {
        let checkBoxList = element.Options.map(function (option, i) {
          return (
            <label className="tw-mr-5" key={i}>
              <input className="form-check-input" ref={element => (refsList.current[option.Id] = element)} id={option.Id} type="checkbox" onChange={handleChange} />
              <span className="tw-pl-2">{option.Name}</span>
            </label>
          );
        });
        template = <div className="tw-grid lg:tw-grid-cols-3 xl:tw-grid-cols-4">{checkBoxList}</div>;
      }
      return (
        <div key={index} className="tw-py-2  ">
          <h6>{element.Name}</h6>
          {template}
        </div>
      );
    });
  }

  return (
    <>
      <div className="Filters" >
        <span className="field tw-cursor-pointer tw-flex tw-items-center" variant="default" onClick={handleShow} >
          <span>{settings?.Dictionaries['SearchForm.Filters'] || dictionaries['SearchForm.Filters'] || 'Filters'}  
            {checkedAttrs > 0 ? <span className="tw-ml-2   badge rounded-pill bg-primary">
              {checkedAttrs}
            </span> : null}
          </span>
          <svg className="svgFilters" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="presentation" focusable="false"><path d="M5 8c1.306 0 2.418.835 2.83 2H14v2H7.829A3.001 3.001 0 1 1 5 8zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm6-8a3 3 0 1 1-2.829 4H2V4h6.17A3.001 3.001 0 0 1 11 2zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path></svg>
        </span>

        <Modal backdrop="static" size="lg" scrollable={true} show={show} onHide={handleClose} onEntered={handleOpenModal} onExited={handleClocedModal}>
          <Modal.Header closeButton>
            <Modal.Title>Filters</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {enablePriceRangeSlider &&
              <PriceRange
                changePriceMode={changePriceMode}
                priceRangeSliderSettings={priceRangeSliderSettings}
                changeNightRange={changeNightRange}
                changeWeekRange={changeWeekRange}
                priceRangeState={priceRangeState}
                resetPrice={isCleanedPrice}
                OpenedModal={startedSearch}
              />}
            {enabledFilters && attrsList}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleReset} className="text-white">
              Reset
            </Button>
            <Button variant="primary" onClick={startSearch} className="text-white">
              Apply
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default Filters;
