import React, { useState, useEffect } from 'react';
import styles from './Guests.module.css';
import DropDownPanel from '../../DropDownPanel/DropDownPanel';
import GuestsCounter from './GuestsCounter/GuestsCounter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';

const Guests = ({ changeAdults, changeChildren, changeInfants, enableGuestFilter, clearField, settings, dictionaries }) => {
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [infants, setInfants] = useState(0);
  const [total, setTotal] = useState(1);

  const [maxGuest] = useState(20);
 

  function adultsHasChanged(val){
    setAdults(val);
    changeAdults(val);
  }

  function childrenHasChanged(val) {
    setChildren(val);
    changeChildren(val);
  }

  function infantsHasChanged(val) {
    setInfants(val);
    changeInfants(val);
  }

  useEffect(() => {
    setTotal(parseInt(adults) + parseInt(children) + parseInt(infants));
  }, [adults, children, infants])

  useEffect(() => {
    if (clearField >0) {
      setAdults(1);
      setChildren(0);
      setInfants(0);
      setTotal(1);
      changeAdults();
      changeChildren();
      changeInfants();
    }
  }, [clearField])

  if(!enableGuestFilter){
    changeAdults(null);
    changeChildren(null);
    changeInfants(null);
  }

 
  return (
    <>
      {
       enableGuestFilter &&
        <div className={styles.Guests}>
             
            <DropDownPanel 
              title={(settings?.Dictionaries['SearchForm.Guest'] || dictionaries['SearchForm.Guest'] || 'Guests') + " " + total}
              icon={<FontAwesomeIcon icon={faUsers} className={'tw-text-primary tw-pr-2'} />}
              className='2xl:tw-w-[250px]'
            >
              <GuestsCounter name="Adults" min='1' max={maxGuest} initial={adults} upCount={adultsHasChanged} settings={settings}  />
              <GuestsCounter name="children" max={maxGuest} initial={children} upCount={childrenHasChanged} settings={settings} />
              <GuestsCounter name="Infants" max={maxGuest} initial={infants} upCount={infantsHasChanged} settings={settings}  />
          </DropDownPanel >
        </div>
      }
    </>
  );
};

Guests.propTypes = {};

Guests.defaultProps = {};

export default Guests;
