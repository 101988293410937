import React, { useState, useRef } from "react";
import useDetectOutsideClick from '../../utils/useDetectOutsideClick'
import "./DropDownPanel.css";


const DropDownPanel = (props) => {

  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
 
  const onClick = (e) => {
    setIsActive(!isActive);
  };
  
  return (
    <div className={`dropDownPanel `}>
      <div className={`menu-container `}>
        <button onClick={onClick} className={`menu-trigger field ${props.className}`}>
          {props.icon}
          <span>{props.title}</span>
        </button>
        <div ref={dropdownRef} className={`menu  ${isActive ? "active" : "inactive"}`} >
          {props.children}
        </div>
      </div>
    </div>
  );
};
 
 

export default DropDownPanel;
